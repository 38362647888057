<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          v-model="solicitudData.numeroPoliza"
          label="Número de póliza"
          placeholder="Requerido"
          :rules="[$rules.required]"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          outlined
          color="#00a7e4"
          @click="$emit('beforeStep')"
          style="margin-right: 11px;"
        >
          <span>
            Anterior
          </span>
        </v-btn>
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="handleSaveClick"
        >
          <span>
           {{ rol == 'AGENT' ? 'Guardar' : 'Siguiente' }} 
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["tipoSolicitud", "ramo"],
  data() {
    return {
      solicitudData: {
        numeroPoliza: null,
      },
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },
  methods: {
    handleSaveClick(){
      this.$emit('goToSave', this.solicitudData)
      console.log("click")
      sessionStorage.setItem("polizaDataSolicitud", this.solicitudData.numeroPoliza)
      switch (this.ramo) {
        case "AUTOS":
          window.dataLayer.push({
            event: this.tipoSolicitud == "Cancelación" 
            ? "ITFY_solicitud_cancelacion_auto"
            : this.tipoSolicitud == "Endoso A y D"
            ? "ITFY_solicitud_endosoAD_auto"
            : this.tipoSolicitud == "Endoso B"
            ? "ITFY_solicitud_endosoB_auto"
            : this.tipoSolicitud == "Facturacion"
            ? "ITFY_solicitud_facturacion_auto"
            : this.tipoSolicitud == "Otro"
            ? "ITFY_solicitud_otro_auto"
            : this.tipoSolicitud,
            userID: localStorage.getItem("agenteMail"),
            poliza: this.solicitudData.numeroPoliza,
          })
          break;
        case "SALUD":
        window.dataLayer.push({
            event: this.tipoSolicitud == "Cancelación" 
            ? "ITFY_solicitud_cancelacion_salud"
            : this.tipoSolicitud == "Endoso A y D"
            ? "ITFY_solicitud_endosoAD_salud"
            : this.tipoSolicitud == "Endoso B"
            ? "ITFY_solicitud_endosoB_salud"
            : this.tipoSolicitud == "Facturacion"
            ? "ITFY_solicitud_facturacion_salud"
            : this.tipoSolicitud == "Otro"
            ? "ITFY_solicitud_otro_salud"
            : this.tipoSolicitud,
            userID: localStorage.getItem("agenteMail"),
            poliza: this.solicitudData.numeroPoliza,
          })
          break;
        case "AHORRO":
        window.dataLayer.push({
            event: this.tipoSolicitud == "Cancelación" 
            ? "ITFY_solicitud_cancelacion_ahorro"
            : this.tipoSolicitud == "Endoso A y D"
            ? "ITFY_solicitud_endosoAD_ahorro"
            : this.tipoSolicitud == "Endoso B"
            ? "ITFY_solicitud_endosoB_ahorro"
            : this.tipoSolicitud == "Facturacion"
            ? "ITFY_solicitud_facturacion_ahorro"
            : this.tipoSolicitud == "Otro"
            ? "ITFY_solicitud_otro_ahorro"
            : this.tipoSolicitud,
            userID: localStorage.getItem("agenteMail"),
            poliza: this.solicitudData.numeroPoliza,
          });
          break;
        case "FIANZA":
        window.dataLayer.push({
            event: this.tipoSolicitud == "Cancelación" 
            ? "ITFY_solicitud_cancelacion_fianza"
            : this.tipoSolicitud == "Endoso A y D"
            ? "ITFY_solicitud_endosoAD_fianza"
            : this.tipoSolicitud == "Endoso B"
            ? "ITFY_solicitud_endosoB_fianza"
            : this.tipoSolicitud == "Facturacion"
            ? "ITFY_solicitud_facturacion_fianza"
            : this.tipoSolicitud == "Otro"
            ? "ITFY_solicitud_otro_fianza"
            : this.tipoSolicitud,
            userID: localStorage.getItem("agenteMail"),
            poliza: this.solicitudData.numeroPoliza,
          });
          break;
          case "VIDA":
        window.dataLayer.push({
            event: this.tipoSolicitud == "Cancelación" 
            ? "ITFY_solicitud_cancelacion_vida"
            : this.tipoSolicitud == "Endoso A y D"
            ? "ITFY_solicitud_endosoAD_vida"
            : this.tipoSolicitud == "Endoso B"
            ? "ITFY_solicitud_endosoB_vida"
            : this.tipoSolicitud == "Facturacion"
            ? "ITFY_solicitud_facturacion_vida"
            : this.tipoSolicitud == "Otro"
            ? "ITFY_solicitud_otro_vida"
            : this.tipoSolicitud,
            userID: localStorage.getItem("agenteMail"),
            poliza: this.solicitudData.numeroPoliza,
          });
          break;
          case "DAÑOS":
        window.dataLayer.push({
            event: this.tipoSolicitud == "Cancelación" 
            ? "ITFY_solicitud_cancelacion_daño"
            : this.tipoSolicitud == "Endoso A y D"
            ? "ITFY_solicitud_endosoAD_daño"
            : this.tipoSolicitud == "Endoso B"
            ? "ITFY_solicitud_endosoB_daño"
            : this.tipoSolicitud == "Facturacion"
            ? "ITFY_solicitud_facturacion_daño"
            : this.tipoSolicitud == "Otro"
            ? "ITFY_solicitud_otro_daño"
            : this.tipoSolicitud,
            userID: localStorage.getItem("agenteMail"),
            poliza: this.solicitudData.numeroPoliza,
          });
          break;
      }
      // if (this._props.tipoSolicitud = "Cancelación")
    }
  },
  mounted() {
    const userRequestData = JSON.parse(sessionStorage.getItem("userRequestData"));
    switch (this.ramo) {
        case "AUTOS":
          window.dataLayer.push({
            event: this.tipoSolicitud == "Cancelación" 
            ? "ITFY_solicitud_cancelacion_auto_v"
            : this.tipoSolicitud == "Endoso A y D"
            ? "ITFY_solicitud_endosoAD_auto_v"
            : this.tipoSolicitud == "Endoso B"
            ? "ITFY_solicitud_endosoB_auto_v"
            : this.tipoSolicitud == "Facturacion"
            ? "ITFY_solicitud_facturacion_auto_v"
            : this.tipoSolicitud == "Otro"
            ? "ITFY_solicitud_otro_auto_v"
            : this.tipoSolicitud,
            userID: localStorage.getItem("agenteMail"),
            correo: userRequestData?.correo,
            telefono: userRequestData?.telefono,
            no_cotizacion: undefined,
          })
          break;
        case "SALUD":
        window.dataLayer.push({
            event: this.tipoSolicitud == "Cancelación" 
            ? "ITFY_solicitud_cancelacion_salud_v"
            : this.tipoSolicitud == "Endoso A y D"
            ? "ITFY_solicitud_endosoAD_salud_v"
            : this.tipoSolicitud == "Endoso B"
            ? "ITFY_solicitud_endosoB_salud_v"
            : this.tipoSolicitud == "Facturacion"
            ? "ITFY_solicitud_facturacion_salud_v"
            : this.tipoSolicitud == "Otro"
            ? "ITFY_solicitud_otro_salud_v"
            : this.tipoSolicitud,
            userID: localStorage.getItem("agenteMail"),
            correo: userRequestData?.correo,
            telefono: userRequestData?.telefono,
            no_cotizacion: undefined,
          })
          break;
        case "AHORRO":
        window.dataLayer.push({
            event: this.tipoSolicitud == "Cancelación" 
            ? "ITFY_solicitud_cancelacion_ahorro_v"
            : this.tipoSolicitud == "Endoso A y D"
            ? "ITFY_solicitud_endosoAD_ahorro_v"
            : this.tipoSolicitud == "Endoso B"
            ? "ITFY_solicitud_endosoB_ahorro_v"
            : this.tipoSolicitud == "Facturacion"
            ? "ITFY_solicitud_facturacion_ahorro_v"
            : this.tipoSolicitud == "Otro"
            ? "ITFY_solicitud_otro_ahorro_v"
            : this.tipoSolicitud,
            userID: localStorage.getItem("agenteMail"),
            correo: userRequestData?.correo,
            telefono: userRequestData?.telefono,
            no_cotizacion: undefined,
          });
          break;
        case "FIANZA":
        window.dataLayer.push({
            event: this.tipoSolicitud == "Cancelación" 
            ? "ITFY_solicitud_cancelacion_fianza_v"
            : this.tipoSolicitud == "Endoso A y D"
            ? "ITFY_solicitud_endosoAD_fianza_v"
            : this.tipoSolicitud == "Endoso B"
            ? "ITFY_solicitud_endosoB_fianza_v"
            : this.tipoSolicitud == "Facturacion"
            ? "ITFY_solicitud_facturacion_fianza_v"
            : this.tipoSolicitud == "Otro"
            ? "ITFY_solicitud_otro_fianza_v"
            : this.tipoSolicitud,
            userID: localStorage.getItem("agenteMail"),
            correo: userRequestData?.correo,
            telefono: userRequestData?.telefono,
            no_cotizacion: undefined,
          });
          break;
          case "VIDA":
        window.dataLayer.push({
            event: this.tipoSolicitud == "Cancelación" 
            ? "ITFY_solicitud_cancelacion_vida_v"
            : this.tipoSolicitud == "Endoso A y D"
            ? "ITFY_solicitud_endosoAD_vida_v"
            : this.tipoSolicitud == "Endoso B"
            ? "ITFY_solicitud_endosoB_vida_v"
            : this.tipoSolicitud == "Facturacion"
            ? "ITFY_solicitud_facturacion_vida_v"
            : this.tipoSolicitud == "Otro"
            ? "ITFY_solicitud_otro_vida_v"
            : this.tipoSolicitud,
            userID: localStorage.getItem("agenteMail"),
            correo: userRequestData?.correo,
            telefono: userRequestData?.telefono,
            no_cotizacion: undefined,
          });
          break;
          case "DAÑOS":
        window.dataLayer.push({
            event: this.tipoSolicitud == "Cancelación" 
            ? "ITFY_solicitud_cancelacion_daño_v"
            : this.tipoSolicitud == "Endoso A y D"
            ? "ITFY_solicitud_endosoAD_daño_v"
            : this.tipoSolicitud == "Endoso B"
            ? "ITFY_solicitud_endosoB_daño_v"
            : this.tipoSolicitud == "Facturacion"
            ? "ITFY_solicitud_facturacion_daño_v"
            : this.tipoSolicitud == "Otro"
            ? "ITFY_solicitud_otro_daño_v"
            : this.tipoSolicitud,
            userID: localStorage.getItem("agenteMail"),
            correo: userRequestData?.correo,
            telefono: userRequestData?.telefono,
            no_cotizacion: undefined,
          });
          break;
      }
  }
};
</script>
