<template>
  <div>
    <v-row v-if="showDatosVigencia">
      <v-col
        v-if="tipoSolicitud == 'Renovación'"
        cols="12"
        md="6"
        lg="3"
        xl="3"
      >
        <v-text-field
          v-model="polizaData.numeroPoliza"
          label="Número de póliza"
          placeholder="Requerido"
          :rules="[$rules.required]"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          v-model="polizaData.anios"
          label="Años de Vigencia"
          placeholder="Requerido"
          :rules="[$rules.required, $rules.numeric]"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          v-model="polizaData.inicioVigencia"
          type="date"
          label="Inicio de Vigencia"
          placeholder="Requerido"
          :rules="[$rules.required]"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          v-model="polizaData.finVigencia"
          type="date"
          label="Fin de Vigencia"
          placeholder="Calculado automáticamente"
          :rules="[$rules.required]"
          outlined
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-select
          v-model="polizaData.periodicidadPago"
          label="Periodicidad de Pago"
          :items="periodicidadItems"
          item-text="text"
          item-value="value"
          :rules="[$rules.required]"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-select
          v-model="polizaData.formaPagoSelected"
          label="Forma de Pago"
          :items="formaPago"
          :rules="[$rules.required]"
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Tipo de Vehículo"
          placeholder="Requerido"
          v-model="polizaData.tipo_vehiculo"
          :items="tipoVehiculosItems"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Uso del Auto"
          v-model="polizaData.uso_auto"
          :items="usoAutoList"
          :rules="inputsRequeridos"
          outlined
          color="#00a7e4"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Origen del Auto"
          v-model="polizaData.origenAuto"
          :items="origenAutoList"
          :rules="inputsRequeridos"
          outlined
          color="#00a7e4"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Modelo"
          placeholder="Requerido"
          v-model="polizaData.modelo"
          :items="modelos"
          item-value="id"
          item-text="id"
          auto-select-first
          @change="getMarcas()"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Marca"
          placeholder="Requerido"
          v-model="polizaData.marca"
          :items="marcas"
          item-value="marca"
          item-text="marca"
          no-data-text="Primero selecciona un modelo"
          auto-select-first
          @change="getSubmarcas()"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Submarca"
          placeholder="Requerido"
          v-model="polizaData.submarca"
          :items="submarcas"
          item-value="nombre"
          item-text="nombre"
          no-data-text="Primero selecciona una marca"
          auto-select-first
          @change="getVersiones()"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Version"
          placeholder="Requerido"
          v-model="polizaData.version"
          :items="versiones"
          item-value="nombre"
          item-text="nombre"
          no-data-text="Primero selecciona una submarca"
          auto-select-first
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Plan"
          v-model="polizaData.coberturasSelected"
          :items="planList"
          item-text="name"
          item-value="name"
          :rules="inputsRequeridos"
          outlined
          color="#00a7e4"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3" v-if="tipoSolicitud != 'Cotización'">
        <v-text-field
          label="No de Motor"
          v-model="polizaData.numMotor"
          :rules="inputsRequeridos"
          outlined
          color="#00a7e4"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3" v-if="tipoSolicitud != 'Cotización'">
        <v-text-field
          label="No. De Serie"
          v-model="polizaData.numSerie"
          :maxLength="17"
          :rules="[
            ...inputsRequeridos,
            localRules.alphanumeric,
            $rules.min(15),
            $rules.max(17),
          ]"
          outlined
          color="#00a7e4"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3" v-if="tipoSolicitud != 'Cotización'">
        <v-text-field
          label="Placas"
          v-model="polizaData.placas"
          :maxLength="10"
          :rules="[...inputsRequeridos, localRules.alphanumeric]"
          outlined
          color="#00a7e4"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          outlined
          color="#00a7e4"
          @click="$emit('beforeStep')"
          style="margin-right: 11px;"
        >
          <span>
            Anterior
          </span>
        </v-btn>
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="handleSaveClick"
        >
          <span>
            {{ rol == 'AGENT' ? 'Guardar' : 'Siguiente' }} 
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mainAxios } from "@/mainAxios.js";

export default {
  props: ["avisar", "tipoSolicitud"],

  data() {
    return {
      polizaData: {
        numeroPoliza: null,
        anios: null,
        inicioVigencia: null,
        finVigencia: null,
        periodicidadPago: null,
        formaPagoSelected: null,
        modelo: null,
        marca: null,
        submarca: null,
        version: null,
        detalle_auto: null,
        plan_id: null,
        origenAuto: null,
        uso_auto: null,
        numMotor: null,
        numSerie: null,
        placas: null,
      },

      modelos: [],
      marcas: [],
      submarcas: [],
      versiones: [],

      localRules: {
        alphanumeric: (v) =>
          !v ||
          !!v.match(/^[0-9a-zA-Z]+$/) ||
          "Este campo solo acepta letras y números",
      },

      //listas
      planList: [
        { id: 1, name: "Amplia" },
        { id: 14, name: "Amplia-Plus" },
        { id: 2, name: "Limitada" },
        { id: 3, name: "RC" },
      ],
      origenAutoList: [
        "Fronterizo",
        "Importado",
        "Legalizado",
        "Nacional",
        "Salvamento",
      ],
      usoAutoList: [
        "Particular",
        "Comercial",
        "Uso con App",
        "Servicio público federal",
        "Transporte público",
        "Taxi",
      ],
      periodicidadItems: [
        { text: "Anual", value: 1 },
        { text: "Semestral", value: 2 },
        { text: "Trimestral", value: 3 },
        { text: "Mensual", value: 4 },
      ],
      formaPago: [
        "Tarjeta de Crédito",
        "Tarjeta de Débito",
        "Deposito Bancario",
        "Transferencia",
        "Cheque",
        "Efectivo",
        "Domiciliación",
      ],
      tipoVehiculosItems: [
        "Auto",
        "Motocicleta",
        "Pick-up",
        "Camión",
        "Tractocamión",
      ],
      axiosConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },

      //Validadores
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,

    };
  },

  computed: {
    marcaId() {
      if (!this.polizaData.marca) return null;
      return this.marcas.find((m) => m.marca == this.polizaData.marca).id;
    },

    showDatosVigencia() {
      return !!["Cotización", "Emisión", "Renovación"].find(
        (tipo) => tipo == this.tipoSolicitud
      );
    },
  },

  watch: {
    "polizaData.anios"(v) {
      if (this.polizaData.inicioVigencia && !!v) {
        const inicioDate = new Date(this.polizaData.inicioVigencia);
        inicioDate.setFullYear(inicioDate.getFullYear() + Number(v));
        this.polizaData.finVigencia = inicioDate.toISOString().split("T")[0];
      }
    },

    "polizaData.inicioVigencia"(v) {
      if (this.polizaData.anios && !!v) {
        const inicioDate = new Date(v);
        inicioDate.setFullYear(
          inicioDate.getFullYear() + Number(this.polizaData.anios)
        );
        this.polizaData.finVigencia = inicioDate.toISOString().split("T")[0];
      }
    },
  },

  mounted() {
    this.getModelos();
    this.setInitalData();
    const userRequestData = JSON.parse(sessionStorage.getItem("userRequestData"));
    window.dataLayer.push({
      event: this.tipoSolicitud == "Cotización" 
      ? "ITFY_solicitud_cotizacion_auto_v" 
      : this.tipoSolicitud == "Emisión" 
      ? "ITFY_solicitud_emision_auto_v"
      : this.tipoSolicitud == "Renovación"
      ? "ITFY_solicitud_renovacion_auto_v"
      : "",
      no_cotizacion: undefined,
      correo: userRequestData?.correo,
      telefono: userRequestData?.telefono,
      userID: localStorage.getItem("agenteMail"),
    })
  },

  methods: {
    setInitalData() {
      if (this.showDatosVigencia) {
        this.polizaData.inicioVigencia = new Date().toISOString().split("T")[0];
      }
    },

    handleSaveClick(){
      this.$emit('goToSave', this.polizaData); 
      if (this.tipoSolicitud == "Cotización" || this.tipoSolicitud == "Emisión"){
        window.dataLayer.push({
          event: this.tipoSolicitud == "Cotización" 
          ? "ITFY_solicitud_cotizacion_auto" 
          : this.tipoSolicitud == "Emisión" 
          ? "ITFY_solicitud_emision_auto"
          : "",
          modalidad_pago: this.polizaData.periodicidadPago == 1 ? "Anual" : this.polizaData.periodicidadPago == 2 ? "Semestral" : this.polizaData.periodicidadPago == 3 ? "Trimestral" : this.polizaData.periodicidadPago == 4 ? "Mensual" : this.polizaData.periodicidadPago,
          tipo_vehiculo: this.polizaData.tipo_vehiculo,
          plan: this.polizaData.coberturasSelected,
          marca: this.polizaData.marca,
          anio: this.polizaData.modelo,
          modelo: this.polizaData.version,
          userID: localStorage.getItem("agenteMail"),
        })
      } else if (this.tipoSolicitud == "Renovación"){
        window.dataLayer.push({
          event: "ITFY_solicitud_renovacion_auto",
          modalidad_pago: this.polizaData.periodicidadPago == 1 ? "Anual" : this.polizaData.periodicidadPago == 2 ? "Semestral" : this.polizaData.periodicidadPago == 3 ? "Trimestral" : this.polizaData.periodicidadPago == 4 ? "Mensual" : this.polizaData.periodicidadPago,
          tipo_vehiculo: this.polizaData.tipo_vehiculo,
          plan: this.polizaData.coberturasSelected,
          marca: this.polizaData.marca,
          anio: this.polizaData.modelo,
          modelo: this.polizaData.version,
          poliza: this.polizaData.numeroPoliza,
          userID: localStorage.getItem("agenteMail"),
      })
    }
    },

    getModelos() {
      mainAxios
        .get("/v1/vehiculo/qualitas/modelos", this.axiosConfig)
        .then(({ data }) => {
          this.modelos = data;
        });
    },

    getMarcas() {
      mainAxios
        .get(
          `/v1/vehiculo/qualitas/marca/${this.polizaData.modelo}`,
          this.axiosConfig
        )
        .then(({ data }) => {
          this.marcas = data;
        });
    },

    getSubmarcas() {
      mainAxios
        .get(
          `/v1/vehiculo/qualitas/submarca/${this.marcaId}/${this.polizaData.modelo}`,
          this.axiosConfig
        )
        .then(({ data }) => {
          this.submarcas = data;
        });
    },

    getVersiones() {
      mainAxios
        .get(
          `/v1/vehiculo/qualitas/vehiculo/${this.marcaId}/${this.polizaData.submarca}/${this.polizaData.modelo}`,
          this.axiosConfig
        )
        .then(({ data }) => {
          this.versiones = data;
        });
    },
  },
};
</script>
