<template>
  <div>
    <v-row v-if="showDatosVigencia">
      <v-col
        v-if="tipoSolicitud == 'Renovación'"
        cols="12"
        md="6"
        lg="3"
        xl="3"
      >
        <v-text-field
          v-model="polizaData.numeroPoliza"
          label="Número de póliza"
          placeholder="Requerido"
          :rules="[$rules.required]"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          v-model="polizaData.anios"
          label="Años de Vigencia"
          placeholder="Requerido"
          :rules="[$rules.required, $rules.numeric]"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          v-model="polizaData.inicioVigencia"
          type="date"
          label="Inicio de Vigencia"
          placeholder="Requerido"
          :rules="[$rules.required]"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          v-model="polizaData.finVigencia"
          type="date"
          label="Fin de Vigencia"
          placeholder="Calculado automáticamente"
          :rules="[$rules.required]"
          outlined
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-select
          v-model="polizaData.periodicidadPago"
          label="Periodicidad de Pago"
          :items="periodicidadItems"
          item-text="text"
          item-value="value"
          :rules="[$rules.required]"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-select
          v-model="polizaData.formaPagoSelected"
          label="Forma de Pago"
          :items="formaPago"
          :rules="[$rules.required]"
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="6" xl="6">
        <v-select
          label="Adjuntar Contrato"
          v-model="polizaData.contratoAdjunto"
          :items="contratoAdjuntoList"
          :rules="[$rules.required]"
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          outlined
          color="#00a7e4"
          @click="$emit('beforeStep')"
          style="margin-right: 11px;"
        >
          <span>
            Anterior
          </span>
        </v-btn>
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="handleSaveClick"
        >
          <span>
            {{ rol == 'AGENT' ? 'Guardar' : 'Siguiente' }} 

          </span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["tipoSolicitud"],

  directives: {
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },
  data() {
    return {
      polizaData: {
        numeroPoliza: null,
        anios: null,
        inicioVigencia: null,
        finVigencia: null,
        periodicidadPago: null,
        formaPagoSelected: null,
        contratoAdjunto: null,
      },

      //listas
      contratoAdjuntoList: ["Si", "No"],

      //Validadores
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],

      periodicidadItems: [
        { text: "Anual", value: 1 },
        { text: "Semestral", value: 2 },
        { text: "Trimestral", value: 3 },
        { text: "Mensual", value: 4 },
      ],
      formaPago: [
        "Tarjeta de Crédito",
        "Tarjeta de Débito",
        "Deposito Bancario",
        "Transferencia",
        "Cheque",
        "Efectivo",
        "Domiciliación",
      ],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },

  watch: {
    "polizaData.anios"(v) {
      if (this.polizaData.inicioVigencia && !!v) {
        const inicioDate = new Date(this.polizaData.inicioVigencia);
        inicioDate.setFullYear(inicioDate.getFullYear() + Number(v));
        this.polizaData.finVigencia = inicioDate.toISOString().split("T")[0];
      }
    },

    "polizaData.inicioVigencia"(v) {
      if (this.polizaData.anios && !!v) {
        const inicioDate = new Date(v);
        inicioDate.setFullYear(
          inicioDate.getFullYear() + Number(this.polizaData.anios)
        );
        this.polizaData.finVigencia = inicioDate.toISOString().split("T")[0];
      }
    },
  },

  methods: {
    setInitalData() {
      if (this.showDatosVigencia) {
        this.polizaData.inicioVigencia = new Date().toISOString().split("T")[0];
      }
    },
    handleSaveClick() {
      this.$emit('goToSave', this.polizaData)
      if (this.tipoSolicitud == "Cotización" || this.tipoSolicitud == "Emisión"){
        window.dataLayer.push({
          event: this.tipoSolicitud == "Cotización" 
          ? "ITFY_solicitud_cotizacion_fianza" 
          : this.tipoSolicitud == "Emisión" 
          ? "ITFY_solicitud_emision_fianza"
          : this.tipoSolicitud,
          modalidad_pago: this.polizaData.periodicidadPago == 1 ? "Anual" : this.polizaData.periodicidadPago == 2 ? "Semestral" : this.polizaData.periodicidadPago == 3 ? "Trimestral" : this.polizaData.periodicidadPago == 4 ? "Mensual" : this.polizaData.periodicidadPago,
          userID: localStorage.getItem("agenteMail"),
        })
      } else if (this.tipoSolicitud == "Renovación"){
        window.dataLayer.push({
          event: "ITFY_solicitud_renovacion_fianza",
          modalidad_pago: this.polizaData.periodicidadPago == 1 ? "Anual" : this.polizaData.periodicidadPago == 2 ? "Semestral" : this.polizaData.periodicidadPago == 3 ? "Trimestral" : this.polizaData.periodicidadPago == 4 ? "Mensual" : this.polizaData.periodicidadPago,
          poliza: this.polizaData.numeroPoliza,
          total_pago: this.polizaData.valorPlan,
          userID: localStorage.getItem("agenteMail"),
      })
    }
  }
  },

  computed: {
    showDatosVigencia() {
      return !!["Cotización", "Emisión", "Renovación"].find(
        (tipo) => tipo == this.tipoSolicitud
      );
    },
  },
  mounted() {
    this.setInitalData();
    const userRequestData = JSON.parse(sessionStorage.getItem("userRequestData"));
    window.dataLayer.push({
      event: this.tipoSolicitud == "Cotización" 
      ? "ITFY_solicitud_cotizacion_fianza_v" 
      : this.tipoSolicitud == "Emisión" 
      ? "ITFY_solicitud_emision_fianza_v"
      : this.tipoSolicitud == "Renovación"
      ? "ITFY_solicitud_renovacion_fianza_v"
      : "",
      no_cotizacion: undefined,
      correo: userRequestData?.correo,
      telefono: userRequestData?.telefono,
      userID: localStorage.getItem("agenteMail"),
    })
  },
};
</script>
