<template>
  <div class="pt-5">
    <CommonExpansionPanel :currentPanel="currentPanel" :readonly="readonly">
      <template v-slot:title-panel>
        Seguimiento de la Solicitud
      </template>
      <v-expansion-panel-content>
        <v-form ref="firstStepForm" :lazy-validation="true">
          <v-row>
            <!-- <v-col cols="12" md="6" v-if="!isAgente">
              <v-autocomplete
                :items="ejecutivos"
                label="Ejecutivo"
                outlined
                v-model="solicitudData.operadorUuid"
                item-text="nombre"
                item-value="uuid"
              ></v-autocomplete>
            </v-col> -->
            <v-col v-if="!isAgente" cols="12" md="6">
              <v-select
                v-model="solicitudData.procesoCotizacionSelected"
                :items="procesoCotizacion"
                label="Proceso de la Cotización"
                outlined
                readonly
              ></v-select>
            </v-col>
            <v-col v-if="!isAgente" cols="12" md="6">
              <v-select
                v-model="solicitudData.solicitudCompletaSelected"
                label="¿Solicitud Completa?"
                :items="solicitudCompleta"
                outlined
              ></v-select>
            </v-col>
            <v-col v-if="!isAgente" cols="12" md="3">
              <v-checkbox
                label="Pendiente de Información"
                v-model="solicitudData.checkPendienteInfo"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="3" v-if="!isAgente">
              <v-checkbox
                label="Cancelación"
                v-model="solicitudData.checkCancelacion"
              ></v-checkbox>
            </v-col>
            <v-col
              v-if="!isAgente && solicitudData.checkPendienteInfo"
              cols="12"
              md="6"
            >
              <v-textarea
                label="Causa de Pendiente de Información"
                v-model="solicitudData.causaPendienteInformacion"
                rows="1"
                auto-grow
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="text-align: end;"
            >
              <v-btn
                rounded
                dark
                outlined
                color="#00a7e4"
                @click="$emit('ChangeOnlyStep', 3, null)"
                style="margin-right: 11px;"
              >
                <span>
                  Anterior
                </span>
              </v-btn>
              <v-btn rounded dark class="common-botton" @click="validateStep">
                <span style="padding-right: 11%;">
                  Guardar
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import { mainAxios } from "@/mainAxios.js";

export default {
  components: {
    CommonExpansionPanel,
  },

  props: {
    currentStep: Number,
  },

  data() {
    return {
      solicitudData: {
        operadorUuid: null,
        procesoCotizacionSelected: "Cotización",
        solicitudCompletaSelected: false,
        checkPendienteInfo: false,
        checkCancelacion: false,
        causaPendienteInformacion: null,
      },
      requiredRule: [(v) => !!v || "El campo es requerido"],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      currentPanel: null,
      readonly: true,
      ejecutivos: [],
      procesoCotizacion: ["Cotización", "Emisión", "Finalizado"],
      solicitudCompleta: ["Si", "No"],
    };
  },
  watch: {
    currentStep(val) {
      if (val == 4) {
        this.currentPanel = 0;
        this.readonly = false;
      } else {
        this.currentPanel = 1;
        this.readonly = true;
      }
    },
  },
  computed: {
    isAgente() {
      return this.rol == "AGENT";
    },

    isAgenteDirecto() {
      const agente = this.agentes.find(
        (a) => a.id == this.solicitudData.agente
      );
      if (!agente) return false;

      return !!agente.canalList.find((canal) => canal.id == 3);
    },

    esInbound() {
      return this.rol.includes("INBOUND");
    },
  },
  methods: {
    validateStep() {
      if (this.$refs.firstStepForm.validate()) {
        this.$emit("goToSave", this.solicitudData);
      }
    },

    beforeStep() {
      this.$emit("ChangeOnlyStep", 2, null);
    },

    obtenerEjecutivos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/agentes/operador", config).then((response) => {
        response.data.map((element) => {
          this.ejecutivos.push({
            nombre:
              `${element.nombre}` +
              " " +
              `${element.apellidoPaterno}` +
              " " +
              `${element.apellidoMaterno ? element.apellidoMaterno : ""}`,
            id: element.id,
            uuid: element.uuid,
          });
          if (this.rol == "OPERADOR") {
            localStorage.agenteUuid == element.uuid
              ? (this.form.ejecutivo = element.uuid)
              : "";
          }
        });
      });
    },
  },

  mounted() {
    this.obtenerEjecutivos();
  },
};
</script>
