<template>
  <div class="pt-5">
    <CommonExpansionPanel :currentPanel="currentPanel" :readonly="readonly">
      <template v-slot:title-panel>
        Datos Generales
      </template>
      <v-expansion-panel-content>
        <v-form ref="firstStepForm" :lazy-validation="true">
          <v-row>
            <v-col m="12" md="6" lg="3" xl="3" v-if="tipoSolicitud == 'Renovación'">
              <v-text-field
                label="No. de Póliza a renovar"
                v-model="onlyRenovacion.polizaRenovar"
                :rules="requiredRule"
                outlined
              ></v-text-field>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3" v-if="tipoSolicitud == 'Renovación'">
              <v-text-field
                label="Fecha Inicio de vigencia a renovar"
                v-model="onlyRenovacion.fechaInicioVigenciaRenovar"
                :rules="requiredRule"
                type="date"
                outlined
              ></v-text-field>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3" v-if="tipoSolicitud == 'Renovación'">
              <v-text-field
                label="Fecha Fin de vigencia a renovar"
                v-model="onlyRenovacion.fechaFinVigenciaRenovar"
                type="date"
                :rules="requiredRule"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <NumeroPolizaForm
            v-if="showNumeroPolizaForm"
            :tipoSolicitud="tipoSolicitud"
            :ramo="ramo"
            @beforeStep="beforeStep"
            @goToSave="goToSave"
          />
          <FormAutos
            v-else-if="ramo == 'AUTOS'"
            :tipoSolicitud="tipoSolicitud"
            @beforeStep="beforeStep"
            @goToSave="goToSave"
          />
          <FormAhorro
            v-else-if="ramo == 'AHORRO'"
            :tipoSolicitud="tipoSolicitud"
            @beforeStep="beforeStep"
            @goToSave="goToSave"
          />
          <FormDaños
            v-else-if="ramo == 'DAÑOS'"
            :tipoSolicitud="tipoSolicitud"
            @beforeStep="beforeStep"
            @goToSave="goToSave"
          />
          <FormFinanzas
            v-else-if="ramo == 'FIANZA'"
            :tipoSolicitud="tipoSolicitud"
            @beforeStep="beforeStep"
            @goToSave="goToSave"
          />
          <FormVida
            v-else-if="ramo == 'VIDA'"
            :tipoSolicitud="tipoSolicitud"
            @beforeStep="beforeStep"
            @goToSave="goToSave"
          />
          <FormSalud
            v-else-if="ramo == 'SALUD'"
            :tipoSolicitud="tipoSolicitud"
            @beforeStep="beforeStep"
            @goToSave="goToSave"
          />
          <!-- <FormTecnologia  v-if="ramo == 'AUTOS'"  @beforeStep="beforeStep" @goToSave="goToSave" /> -->
        </v-form>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import { mainAxios } from "@/mainAxios.js";
import FormAutos from "./RamosForms/FormAutos.vue";
import FormAhorro from "./RamosForms/FormAhorro.vue";
import FormDaños from "./RamosForms/FormDaños.vue";
import FormFinanzas from "./RamosForms/FormFinanzas.vue";
import FormSalud from "./RamosForms/FormSalud.vue";
import FormVida from "./RamosForms/FormVida.vue";
import NumeroPolizaForm from "./NumeroPolizaForm.vue";
// import FormTecnologia from "./RamosForms/FormTecnologia.vue"
import moment from "moment";
import RfcFacil from "rfc-facil";

export default {
  components: {
    CommonExpansionPanel,
    FormAutos,
    FormAhorro,
    FormDaños,
    FormFinanzas,
    FormSalud,
    FormVida,
    NumeroPolizaForm,
    // FormTecnologia
  },
  props: {
    currentStep: Number,
    ramo: {
      Type: String,
      default: null,
    },
    tipoSolicitud: {
      Type: String,
      default: null,
    },
  },
  data() {
    return {
      polizaData: {
        regimen: null,
        razonSocial: null,
        rfc: null,
        nombres: null,
        fechaNacimiento: null,
        edad: null,
      },
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      regimentList: ["Física", "Moral"],
      rfcRules: [
        (v) =>
          (v || "").length <= 13 || "RFC No debe ser mayor de 13 caracteres",
        (v) => !!v || "Campo Requerido",
        (v) =>
          /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/.test(
            v
          ) || "RFC no es valido recordar escribir en mayusculas",
      ],
      requiredRule: [(v) => !!v || "El campo es requerido"],
      generoList: ["Masculino", "Femenino"],
      emailRules: [
        (v) => !!v || "E-mail es requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail debe de ser valido",
      ],
      colonias: [],
      estados: [
        { id: 1, name: "Aguascalientes" },
        { id: 2, name: "Baja California" },
        { id: 3, name: "Baja California Sur" },
        { id: 4, name: "Campeche" },
        { id: 5, name: "Coahuila de Zaragoza" },
        { id: 6, name: "Colima" },
        { id: 7, name: "Chiapas" },
        { id: 8, name: "Chihuahua" },
        { id: 9, name: "Ciudad de México" },
        { id: 10, name: "Durango" },
        { id: 11, name: "Guanajuato" },
        { id: 12, name: "Guerrero" },
        { id: 13, name: "Hidalgo" },
        { id: 14, name: "Jalisco" },
        { id: 15, name: "Estado de México" },
        { id: 16, name: "Michoacán de Ocampo" },
        { id: 17, name: "Morelos" },
        { id: 18, name: "Nayarit" },
        { id: 19, name: "Nuevo León" },
        { id: 20, name: "Oaxaca" },
        { id: 21, name: "Puebla" },
        { id: 22, name: "Querétaro" },
        { id: 23, name: "Quintana Roo" },
        { id: 24, name: "San Luis Potosí" },
        { id: 25, name: "Sinaloa" },
        { id: 26, name: "Sonora" },
        { id: 27, name: "Tabasco" },
        { id: 28, name: "Tamaulipas" },
        { id: 29, name: "Tlaxcala" },
        { id: 30, name: "Veracruz de Ignacio de la Llave" },
        { id: 31, name: "Yucatán" },
        { id: 32, name: "Zacatecas" },
      ],
      currentPanel: null,
      readonly: true,
      onlyRenovacion: {
        polizaRenovar: null,
        fechaInicioVigenciaRenovar: null,
        fechaFinVigenciaRenovar: null,
      },
    };
  },

  computed: {
    showNumeroPolizaForm() {
      return !["Cotización", "Emisión", "Renovación"].find(
        (tipoSolicitud) => tipoSolicitud == this.tipoSolicitud
      );
    },
  },

  watch: {
    "polizaData.fechaNacimiento"(val) {
      var today = moment().format("YYYY-MM-DD");
      var birthday = moment(val, "YYYY-MM-DD");
      this.polizaData.edad = moment(today, "YYYY-MM-DD").diff(
        moment(birthday, "YYYY-MM-DD"),
        "years"
      );
      this.actualizarRfc();
    },
    "polizaData.codigoPostal"(val) {
      if (typeof val != "undefined") {
        if (val.length == 5) {
          const config = {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          };
          mainAxios
            .get("/v1/catalogo/direccion?cp=" + val, config)
            .then((response) => {
              if (response.data.listaColonias.length >= 1) {
                this.colonias = response.data.listaColonias;
                this.polizaData.estadoRepublicaSelected = Number(
                  response.data.nEstado
                );
                this.polizaData.ciudad = response.data.nombreMunicipio;
                this.polizaData.pais = "México";
              } else if (response.error == "") {
                alert("No existe ese codigo postal");
              }
            });
        }
      }
    },
    "polizaData.nombres"() {
      this.actualizarRfc();
    },
    "polizaData.apellidoPaterno"() {
      this.actualizarRfc();
    },
    "polizaData.apellidoMaterno"() {
      this.actualizarRfc();
    },
    currentStep(val) {
      if (val == 3) {
        this.currentPanel = 0;
        this.readonly = false;
      } else {
        this.currentPanel = 1;
        this.readonly = true;
      }
    },
  },
  methods: {
    validateStep() {
      if (this.$refs.firstStepForm.validate()) {
        this.$emit("savePoliza", this.polizaData);
      }
    },
    beforeStep() {
      this.$emit("ChangeOnlyStep", 2, null);
    },
    actualizarRfc() {
      if (
        !this.polizaData.nombres ||
        !this.polizaData.apellidoPaterno ||
        !this.polizaData.apellidoMaterno ||
        !this.polizaData.fechaNacimiento
      )
        return;
      const arregloNacimiento = moment(this.polizaData.fechaNacimiento)
        .format("DD/MM/YYYY")
        .split("/")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
      const rfc = RfcFacil.forNaturalPerson({
        name: this.polizaData.nombres,
        firstLastName: this.polizaData.apellidoPaterno,
        secondLastName: this.polizaData.apellidoMaterno,
        day: arregloNacimiento[0],
        month: arregloNacimiento[1],
        year: arregloNacimiento[2],
      });
      this.polizaData.rfc = rfc.substring(0, rfc.length - 3);
    },
    goToSave(data) {
      data = {
        ...data,
        ...this.onlyRenovacion
      }
      if(this.rol != 'AGENT')
        this.$emit("ChangeOnlyStep", 4, data);
      else
        this.$emit("goToSave", data)
    },
  },
  mounted() {
  },
};
</script>
